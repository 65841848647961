import React,{createContext,useState,useEffect} from "react";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword,signOut } from "firebase/auth";
import {app} from '../firebase';
import { message } from "antd";


export const AuthContext = createContext();





 const AuthContextProvider = ({children}) => {

    const [messageApi, contextHolder] = message.useMessage();
    const [user, setUser] = useState(null);



const auth = getAuth();



const determineRole = (user) => {

    user.getIdTokenResult().then((idTokenResult) => {
        if(idTokenResult.claims.role === 'admin'){
            setUser(user);
        }else{
            messageApi.error("You are not an admin");
           
          logout();
        }
    }
    );
}






const login = async(email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        determineRole(user);
        messageApi.success("Logged In Successfully");
    }
    catch(error){
        console.log(error);
        messageApi.error(error.message);
    }

}



const logout = () => {
    signOut(auth).then(() => {
        setUser(null);
      }).catch((error) => {
        // An error happened.
      });

}

useEffect(() => {
//login('arhamc241@gmail.com','kameo123')


onAuthStateChanged(auth, (user) => {
    if (user) {
     
     determineRole(user);
  
    } else {
     
    }
  }
);




}
,[]);




    return (
        <AuthContext.Provider value={{user,logout,login}}>
            <div>
            {contextHolder}

            </div>
            {children}
        </AuthContext.Provider>
    )
}


export default AuthContextProvider;





