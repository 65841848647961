import React,{useState,useContext} from 'react';
import { Form, Input, Button, Card } from 'antd';
import { AuthContext } from '../context/authContext';

const LoginPage = () => {

    const [loading, setLoading] = useState(false);
    const {login} = useContext(AuthContext);



  const onFinish = async(values) => {


    console.log('Received values:', values);
    setLoading(true);
   
        await login(values.email,values.password);
        setLoading(false);


    
  };

  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
        <Card title="Login" style={{ width: 300 }}>
      <Form
        name="loginForm"
        onFinish={onFinish}
        
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>
      </Form>
      </Card>

    </div>
  );

};

export default LoginPage;
