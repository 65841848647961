import './App.css';
import AuthContextProvider from './context/authContext';
import AppRouter from './layout';


//hello

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
      <AppRouter />
      </AuthContextProvider>

    </div>
  );
}

export default App;
