// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCKiCHTdMwfWBnIAv8mnIbwQleJuKiJc-Q",
  authDomain: "kameo-cabs.firebaseapp.com",
  projectId: "kameo-cabs",
  storageBucket: "kameo-cabs.appspot.com",
  messagingSenderId: "125628726723",
  appId: "1:125628726723:web:0e096522674c6762821176",
  measurementId: "G-T1NR2FTSDV"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);