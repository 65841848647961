import React, { useContext, useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LoginOutlined

} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Typography,ConfigProvider, Space } from 'antd';
import { createHashRouter,RouterProvider } from "react-router-dom";
import RoutePage from './components/RoutePage';
import SeatPage from './components/SeatPage.js';
import LoginPage from './components/LoginPage.js';
import { AuthContext } from './context/authContext.js';


const { Header, Sider, Content } = Layout;

const { Text,Title } = Typography;


const AppRouter = () => {

  const [collapsed, setCollapsed] = useState(false);
  const [currentRoute, setCurrentRoute] = useState("");
  const {user,logout} = useContext(AuthContext)



  


  const router = createHashRouter([
    {
      path: "/route",
      element: <RoutePage />,
},
    {
      path: "/seat",
      element: <SeatPage/>,
    }


  ]);



const getCurrentRoute = () => {

const currentRoute = router.window.location.hash.replace("#","");

setCurrentRoute(currentRoute);
  
}

useEffect(() => {
  getCurrentRoute();
}
,[]);



  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


 

  return (



    <ConfigProvider
    theme={{
      // 1. Use dark algorithm
      algorithm: theme.defaultAlgorithm,

      // 2. Combine dark algorithm and compact algorithm
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
    }}
  >
  {!user?<LoginPage/>:

    <Layout style={{height:"100vh"}} >
      <Sider ba trigger={null} collapsible collapsed={collapsed}>
        {collapsed ? null : 
 <div className='logo'>
    
 <img style={{filter:" invert(1)"}} src="/kameoLogo.svg" atl="logo"/>
 </div>
 

        }
       
        <Menu
          theme="dark"
          mode="inline"

          defaultSelectedKeys={['1']}
          
          items={[
            {
              key: '1',
              icon:<img  style={{width:"24px",height:"20px",filter:" invert(1)"}} src='icons/distance.png' />,
              label: 'Routes',
                onClick: () => {
                    router.navigate('/route');
                    getCurrentRoute();
                },
            },
         
            {
              key: '3',
              icon: <img style={{width:"24px",height:"24px",filter:" invert(1)"}} src='icons/seat.png' />,
              label: 'Seats',
              onClick: () => {
                router.navigate('/seat');
                getCurrentRoute();
            },
            
            
            },
          ]}
        />
    
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Space>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <Text>{currentRoute}</Text>
          </Space>
          <Space >
          <Text><span style={{color:"blue"}}>{user.email}</span> as Admin</Text>
          <Button icon={<LoginOutlined />} type="text" onClick={logout}>Sign Out</Button>
          </Space>
          


        </Header>
        <Content
        className='main-content'
         style={{
          margin: '24px 16px 0',
          overflow: 'scroll',

        }}
        >
         <RouterProvider router={router} />
        </Content>
      </Layout>
    </Layout>
      }
    </ConfigProvider>
    
  );
};
export default AppRouter;