import { Space, Typography,DatePicker,Button,Modal,Form,Input,Card,message, Table } from "antd";
import React,{useContext, useEffect, useState} from "react";
import axios from '../axios'
import { PlusOutlined,DeleteOutlined } from '@ant-design/icons';
import { AuthContext } from "../context/authContext";


const { RangePicker } = DatePicker;



const SeatPage = () => {

  const [cabId, setCabId] = useState(""); 
    const [cabList, setCabList] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [seatLoading, setSeatLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {user} = useContext(AuthContext);
    


    const {Title,Text} = Typography;

    
    const handleCancel = () => {
      setIsModalOpen(false);
  }

  const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    }
  const openSeatModal = (id) => {
      setCabId(id);
      setIsModalOpen(true);
  }




    


 
    const setCols = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
        {
          title: 'Seat Number',
          dataIndex: 'seatNumber',
          key: 'id',
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
             <Button  type="primary"  onClick={() => {openSeatModal(record.id)}}>Edit</Button>
             <Button danger type="primary" icon={<DeleteOutlined />} onClick={() => {deleteSeat(record.id)}}></Button>

            </Space>
          ),
        }
       
     
       
      
         
      ];


      const getSeatList = async() => {

     //   messageApi.loading("Fetching Seat List");

        const token  = user && await user.getIdToken();
        try {

           const response = await axios.get('/seat',{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCabList(response.data);
         //   messageApi.success("Seat List Fetched Successfully");

        } catch (error) {
            console.error(error);
            messageApi.error(error.response.data.message);
        }

    }


    console.log(cabList);


    const deleteSeat = async(id) => {
      const token  = user && await user.getIdToken();

        try {
            const response = await axios.delete(`/seat/${id}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(response);
            messageApi.success("Seat Deleted Successfully");
            getSeatList();
        } catch (error) {
            console.error(error);
            messageApi.error(error.response.data.message);
        }
    }




    const createSeat = async(values) => {
        const token  = user && await user.getIdToken();

       // setSeatLoading(true);

        
        console.log(values);

        const datesArray = []; // to store all the dates between the range
        const startDate = values.date[0];
        const endDate = values.date[1];

        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            datesArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

       


      
        


      Promise.all(datesArray.map(async(date) => {
            try {
                const response = await axios.post('/seat',{
                    cabId: cabId,
                    seatNumber: values.seatNumber,
                    price: values.price,
                    date: date
                },{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
               
            } catch (error) {
                console.error(error);
                messageApi.error(error.response.data.message);
                setSeatLoading(false);
            }
         
        } ));
        messageApi.success("Seat Created Successfully");
        setSeatLoading(false);
        getSeatList();
        setIsModalOpen(false);
    }


    const deleteCab = async(id) => {
        try {
            const response = await axios.delete(`/cab/${id}`);
            console.log(response);
            messageApi.success("Cab Deleted Successfully");
            getSeatList();
        } catch (error) {

            console.error(error);
            messageApi.error(error.response.data.message);
        }
    }
    





    useEffect(() => {
        getSeatList();
    }
    ,[]);




    return (
        <div
        style={{
          padding: "30px",
        }}
        >
            {contextHolder}
            <Modal  title="Create Seat" cancelButtonProps={{ style:{display:"none"} }}   okButtonProps={{ style: { display: 'none' } }}
 open={isModalOpen}  onCancel={handleCancel}>
                <Form
    name="basic"
    labelCol={{
      span: 6,
    }}
    wrapperCol={{
      span: 12,
    }}
    style={{
      maxWidth: 400,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={createSeat}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Seat Number"
      name="seatNumber"
      rules={[{required: true}]}
    >
      <Input type="number" />
    </Form.Item>
    

    <Form.Item
      label="Price"
      name="price"
      rules={[{required: true}]}
    >
      <Input type="number" />
    </Form.Item>

    <Form.Item
      label="Date"
      name="date"
      rules={[{required: true}]}
    >
      <RangePicker />
    </Form.Item>

    <Form.Item >

<Space
style={{
  display: "flex",
  justifyContent: "right",
}}
>
<Button loading={seatLoading}  type="primary" htmlType="submit">
        Create Seat
      </Button>
      <Button onClick={handleCancel} >Cancel</Button>
      
</Space>
     
    </Form.Item>

    



    </Form>

      </Modal>
          {cabList.length>0 && 
            <Space style={{margin:"0px 30px",display:"flex",flexWrap:'wrap'}} size={30} direction="vertical">

            {cabList.map((cab) => (
              <Card
              key={cab.id}
              title={cab.cabNumber} extra={
              <Space>
              <Button style={{marginLeft:"30px"}} type="primary" icon={<PlusOutlined />} onClick={() => {openSeatModal(cab.id,false)}}>Add Seat</Button>
              <Button danger type="primary" icon={<DeleteOutlined />} onClick={() => {deleteCab(cab.id)}}>Delete Cab</Button>

              </Space>
              } >
              <div>
              <Text level={5}>{cab.model}  </Text><br/>
                <Text type="secondary">{cab.color} </Text>
                <Text type="secondary"> {cab.type}</Text>

              </div>
              <div>
              
              <p><Text  type="secondary" >Source: {cab.Route.source}</Text> </p>
              <p><Text  type="secondary" >Destination: {cab.Route.destination}</Text></p>
              <p><Text  type="secondary" >departure Time: {cab.Route.departureTime}</Text></p>
              <p><Text  type="secondary" >boarding Time: {cab.Route.boardingTime}</Text></p>
              </div>
            
         
              <Table dataSource={cab.Seats} columns={setCols} />
             
             
            </Card>
            ))}

            </Space>
          }
       
       
       </div>
           
        
    )
}


export default SeatPage;