import React,{useEffect, useState,useContext} from "react";
import { Button, TimePicker, Form, Input, Space ,Table,Typography,Modal, Select,message, List} from 'antd';
import axios from '../axios'
import { PlusOutlined,DeleteOutlined,ExclamationCircleFilled } from '@ant-design/icons';
import { AuthContext } from "../context/authContext";





const RoutePage = () => {

    const { Title,Text } = Typography;
    const RangePicker = TimePicker.RangePicker;

    const [routeList, setRouteList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cabLoading, setCabLoading] = useState(false);
    const [tloading,setTloading] = useState(false);
    const [routeId, setRouteId] = useState("");
    const [num, setNum] = useState("");

    const [dropModal, setDropModal] = useState(null);
    const [dropPoint, setDropPoint] = useState({name:"",landmark:"",time:""});


    const {messageApi, contextHolder} = message;

    const {user,token} = useContext(AuthContext);

    const { confirm } = Modal;

    const showDeleteConfirm = (id,cabCount) => {
      confirm({
        title: 'Are you sure delete this Route ?',
        icon: <ExclamationCircleFilled />,
        content: `This route has ${cabCount} cabs.`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteRoute(id);
        },
        onCancel() {
         
        },
      });
    };





    const showCabModel = async(routeId) => {
      setIsModalOpen(true);
      setRouteId(routeId);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const columns = [
        { 
          title: 'Source',
          dataIndex: 'source',
          key: 'id',
          render: (text,item) => (
            <Space direction="vertical" >

            <Space>
            <Text strong>{text}</Text>
            <Button size="small"
            onClick={()=>setDropModal({
              visible: true,
              routeId: item.id,
              location: item.source
            })}
            ><PlusOutlined /></Button>
            </Space>
            <ul>
            {item.DropPoints.map((drop) => (
              drop.location === item.source ? <li key={drop.id}>{drop.name} - {drop.landmark} - {drop.time}</li> : null
            ))}
           </ul>
            </Space>
          ),
          
        },
        {
          title: 'Destination',
          dataIndex: 'destination',
          key: 'id',
          render: (text,item) => (
            <Space direction="vertical" >
            <Space>
            <Text strong>{text}</Text>
            <Button size="small"
            onClick={
              ()=>setDropModal({
                visible: true,
                routeId: item.id,
                location: item.destination
              })
            }
            ><PlusOutlined /></Button>
            </Space>
           <ul>
            {item.DropPoints.map((drop) => (
              drop.location === item.destination ? <li key={drop.id}>{drop.name} - {drop.landmark} - {drop.time}</li> : null
            ))}
           </ul>
            </Space>
          ),
        },
        {
          title: 'Boarding Time',
          dataIndex: 'boardingTime',
          key: 'id',
        },
        {
            title: 'Departure Time',
            dataIndex: 'departureTime',
            key: 'id',
          },
          {
            title: 'Cabs',
            dataIndex: 'Cabs',
            key: 'id',
            render: (cabs) => (
              <List
              size="small"
              bordered
              dataSource={cabs}
              renderItem={(cab) => (
                <List.Item>
                  {cab.cabNumber} - {cab.model} - {cab.color}
                </List.Item>
              )}
            />
            )


          },

          {
            title: 'Actions',
            dataIndex: 'id',
            key: 'id',
            render: (id,{cabCount}) => (
              <Space>
            <Button icon={<PlusOutlined />} onClick={()=>showCabModel(id) } >Cab</Button>
            <Button icon={<DeleteOutlined />} danger onClick={()=>showDeleteConfirm(id,cabCount)} >Route</Button>
              </Space>
            )


          }
        ];

const addDropPoint = async() => {
  try{
    const res = await axios.post('dropPoint',{
      ...dropPoint,
      routeId: dropModal.routeId,
      location: dropModal.location
    },{
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    console.log(res.data);
    setDropPoint({name:"",landmark:""});
    setDropModal(false);
    getRouteList();
  } catch(error){
    console.log(error);
  }
};




    const onFinish = async(values) => {

        const boardingTime = values.time[0].format('HH:mm');
        const departureTime = values.time[1].format('HH:mm');
        
        console.log(boardingTime,departureTime);



    try{
        const res = await axios.post('/route',{
            source: values.source,
            destination: values.destination,
            boardingTime: boardingTime,
            departureTime: departureTime
        
        });

        console.log(res.data);
        getRouteList()
      
  
        

    } catch(error){
        console.log(error);
    }
  

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };


      const getRouteList = async() => {

        setTloading(true);
        const token = await user.getIdToken();
       


        try{
            const res = await axios.get('/route',{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(res.data);
            setRouteList(res.data);
            setTloading(false);
        } catch(error){
            console.log(error);
            setTloading(false);
        }
      }

      const deleteRoute = async(id) => {
        try{
            const res = await axios.delete(`/route/${id}`);
            getRouteList();
            

        } catch(error){
            console.log(error);
        }
      }


      const addCab = async(values) => {
        
        setCabLoading(true);

        console.log(routeId);
     

        try{
            const res = await axios.post('/cab',{
                cabNumber: num,
                model: values.model,
                color: values.color,
                type: values.type,
                routeId: parseInt(routeId)
            });
            console.log(res.data);
            setIsModalOpen(false);
            setCabLoading(false);
            getRouteList();

        } catch(error){
            console.log(error);
            setCabLoading(false);
        }
        

      };

     

  const handleNumChange = (e) => {
    let val = e.target.value;
    val = val.replace(/\s/g, '');
    if (val.length > 2) {
      val = val.slice(0, 2) + ' ' + val.slice(2);
    }
    if (val.length > 5) {
      val = val.slice(0, 5) + ' ' + val.slice(5);
    }
    if (val.length > 8) {
      val = val.slice(0, 8) + ' ' + val.slice(8);
    }
    setNum(val.toUpperCase());
  };



      useEffect(() => {
        getRouteList();
    }
    ,[]);



    console.log(routeList);

    return (
        <div>
          {contextHolder}
             <Modal  title="Create Cab" cancelButtonProps={{ style:{display:"none"} }}   okButtonProps={{ style: { display: 'none' } }}
 open={isModalOpen}  onCancel={handleCancel}>
    <Form
    name="basic"
    labelCol={{
      span: 6,
    }}
    wrapperCol={{
      span: 12,
    }}
    style={{
      maxWidth: 400,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={addCab}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
<Space>
<Input placeholder="DL 3S AT 3254" style={{width:"200px"}} maxLength={13} value={num} onChange={handleNumChange}  />

</Space>
<br/>
<br/>
    <Form.Item
      label="Type"
      name="type"
      rules={[{required: true}]}
    >
      <Select>
        <Select.Option value="Sedan">Sedan</Select.Option>
        <Select.Option value="SUV">SUV</Select.Option>
        <Select.Option value="Hatchback">Hatchback</Select.Option>
      </Select>
    </Form.Item>
    

    <Form.Item
      label="Model"
      name="model"
      rules={[{required: true}]}
    >
      <Input

      />
    </Form.Item>

    <Form.Item
      label="Color"
      name="color"
      rules={[{required: true}]}
    >
      <Input />
    </Form.Item>

    <Form.Item >

<Space
style={{
  display: "flex",
  justifyContent: "right",
}}
>
<Button  loading={cabLoading} type="primary" htmlType="submit">
        Add Cab
      </Button>
      <Button onClick={handleCancel} >Cancel</Button>
      
</Space>
     
    </Form.Item>

    



    </Form>

      </Modal>
            
<Modal
okText={"Add"}
onOk={addDropPoint}
title={`Add Drop point for ${dropModal?.location}`}  
  open={dropModal}  onCancel={()=>setDropModal(false)}>
<Space>
<Input value={dropPoint.name} onChange={(e)=>setDropPoint({
  ...dropPoint,
  name: e.target.value
})} placeholder="Name" />
<Input
  value={dropPoint.landmark}
  onChange={(e) =>
    setDropPoint({
      ...dropPoint,
      landmark: e.target.value,
    })
  }
  placeholder="Landmark"
/>
<Input type="time" value={dropPoint.time} onChange={(e)=>{setDropPoint({
  ...dropPoint,
  time: e.target.value
})} } placeholder="Time" />


</Space>

  </Modal>




            <Space style={{width:"100%",justifyContent:'space-around'}} >
        <Form
    name="basic"
 
    style={{
      maxWidth: 800,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >


    <div>

   <Space>

    <Form.Item name="source" rules={[{required: true}]} >
      <Input style={{width:"145px"}}  placeholder="Source" />
    </Form.Item>


    <Form.Item
      name="destination"
      rules={[
        {
          required: true,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input style={{width:"145px"}}   placeholder="Destination" />
    </Form.Item>
    
    </Space>


    </div>
    
    <div>

   
<Form.Item
  name="time"
  rules={[{required: true}]}
>

<RangePicker style={{width:"300px"}} />



</Form.Item>
<Form.Item >
      <Button style={{width:"300px"}} type="primary" htmlType="submit">
        Create Route
      </Button>
    </Form.Item>

</div>
<div>


</div>

   


  </Form>
<div style={{minWidth:"600px"}}>

</div>
  </Space>
    <Title style={{marginLeft:"10px"}} level={4}> Available Routes</Title>
  
  <Table loading={tloading} columns={columns} pagination={false} dataSource={routeList} />
        </div>
    );
    }


export default RoutePage;